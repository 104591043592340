import React, {useState} from "react"
import rockwyou from '../audio/rockwyou.m4a'

export default function Home() {
  const [audio, setAudio] = useState(null);
  const playSong = () => {
    let localAudio = audio;
    if (localAudio == null) {
        localAudio = new Audio(rockwyou);
        setAudio(localAudio);
    }
    
    localAudio.play().then(function() {
      console.log("PLAYING");
    }).catch(function(error) {
      console.log("FAILED TO PLAY");
      console.log(error);
    });
  }
  const pauseSong = () => {
    audio.pause();
  }

  return <div><button onClick={playSong}>PLAY</button><button onClick={pauseSong}>PAUSE</button></div>
}
